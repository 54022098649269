import React, { useEffect } from "react";
import PrivateRoutes from "./routes/PrivateRoutes";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { getToken } from "firebase/messaging";
import { onMessageListener, requestNotificationPermission } from "./firebase";

function App() {

  // async function RequestPermission() {
  //   await Notification.requestPermission().then(async (res) => {
  //     if (res == "granted") {
  //       //   const token  = await getToken(messaging,{vapidKey:"BKHz-Ht4BAlAZhQ2USyjNwWuCiJWf3C_C5UeMMafx6zBrxZOItL8HBZk632qKnsTma48GYeE7Te_S2A-a9mD710"})
  //       //   console.log("Token gen:",token);
  //       const token = await getToken(messaging)
  //       return token;
  //     } else {
  //       alert('You denied for the permission !')
  //     }
  //   }).then((token) => {
  //     console.log("Token :", token);

  //   }).catch((err) => {
  //     console.log("error occure", err);

  //   })
  //   // if (permission == "granted") {
  //   //   const token  = await getToken(messaging,{vapidKey:"BKHz-Ht4BAlAZhQ2USyjNwWuCiJWf3C_C5UeMMafx6zBrxZOItL8HBZk632qKnsTma48GYeE7Te_S2A-a9mD710"})
  //   //   console.log("Token gen:",token);

  //   // } else if (permission == "denied") {
  //   //   alert('You denied for the permission !')
  //   // }
  // }
  useEffect(() => {
    // onMessageListener()
    //   .then((payload) => {
    //     console.log("Message received:", payload);
    //     // Show notification
    //   })
    //   .catch((err) => console.error("Failed to receive message:", err));
    requestNotificationPermission();
  }, [])
  return (
    <>
      <PrivateRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
