
// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBldHnI5fwtrRWY9xtG6681tzjfoc9dQsc",
    authDomain: "bmsgrowthh.firebaseapp.com",
    projectId: "bmsgrowthh",
    storageBucket: "bmsgrowthh.firebasestorage.app",
    messagingSenderId: "564764484011",
    appId: "1:564764484011:web:1449498d38ec39496372ee"
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestNotificationPermission = async () => {
    try {
        const token = await getToken(messaging, {
            vapidKey: "BKHz-Ht4BAlAZhQ2USyjNwWuCiJWf3C_C5UeMMafx6zBrxZOItL8HBZk632qKnsTma48GYeE7Te_S2A-a9mD710",
        });
        if (token) {
            console.log("Token generated:", token);
            // Send this token to your server
        } else {
            console.log("No registration token available.");
        }
    } catch (err) {
        console.error("Error generating token:", err);
    }
};

export const onMessageListener = () => {
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
};
